'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown.show').removeClass('show');
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('li').detach();
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $('.submenuItemContainer').removeClass('show');
    $('.menuItemContainer').removeClass('show');
};

var clearSelectionDesktop = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $('.submenuItemContainer').removeClass('show', 'navSelected');
    $('.menuItemContainer').removeClass('show', 'navSelected');
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown.menuItemsActions:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category d-flex flex-row align-items-center py-2" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false')
                    .addClass('pl-2');
                link.find('.list-menu-next').remove();
                link.find('.list-menu-next-menuItem').remove();
                var backButton = $('<div class="back pl-3 pr-4"><p class="button-menu-back"></p></div>');
                li.append(backButton);
                li.append(link);
                var dropdownMenu = $(this).parent().children('.dropdown-menu');
                // Verifie if close-menu exists
                if (!dropdownMenu.children('.nav-menu').length) {
                    // Clone and prepend of close-menu
                    var closeMenu = $('<li class="nav-menu"></li>');
                    closeMenu.append($('.close-menu').first().clone());
                    dropdownMenu.prepend(li);
                    dropdownMenu.prepend(closeMenu);
                } else {
                    // clone en append
                    li = $('<li class="dropdown-item top-category d-flex flex-row align-items-center py-2" role="button"></li>');
                    link = $(this).clone().removeClass('dropdown-toggle')
                        .removeAttr('data-toggle')
                        .removeAttr('aria-expanded')
                        .attr('aria-haspopup', 'false')
                        .addClass('pl-2');
                    link.find('.list-menu-next').remove();
                    link.find('.list-menu-next-menuItem').remove();
                    backButton = $('<div class="back pl-3 pr-4"><p class="button-menu-back"></p></div>');
                    li.append(backButton);
                    li.append(link);
                    dropdownMenu.children().eq(0).after(li);
                }
                // Ajustar atributos y clases
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('click', function (e) {
            e.preventDefault();
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelectionDesktop(this);
                        });
                        if ($(this).hasClass('show') || $(this).hasClass('navSelected')) {
                            $(this).removeClass('show');
                            $(this).removeClass('navSelected');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('navSelected');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
            e.stopPropagation();
        })

        .on('dblclick', function (e) {
            if (isDesktop(this)) {
                window.location.href = $(this).attr('href');
                e.stopPropagation(); // Evitar la propagación del evento para que no se interprete como un clic individual
            }
        });

        // .on('mouseenter', function () {
        //     if (isDesktop(this)) {
        //         var eventElement = this;
        //         $('.navbar-nav > li').each(function () {
        //             if (!$.contains(this, eventElement)) {
        //                 $(this).find('.show').each(function () {
        //                     clearSelection(this);
        //                 });
        //                 if ($(this).hasClass('show')) {
        //                     $(this).removeClass('show');
        //                     $(this).children('ul.dropdown-menu').removeClass('show');
        //                     $(this).children('.nav-link').attr('aria-expanded', 'false');
        //                 }
        //             }
        //         });
        //         // need to close all the dropdowns that are not direct parent of current dropdown
        //         $(this).parent().addClass('show');
        //         $(this).siblings('.dropdown-menu').addClass('show');
        //         $(this).attr('aria-expanded', 'true');
        //     }
        // })
        // .parent()
        // .on('mouseleave', function () {
        //     if (isDesktop(this)) {
        //         $(this).removeClass('show');
        //         $(this).children('.dropdown-menu').removeClass('show');
        //         $(this).children('.nav-link').attr('aria-expanded', 'false');
        //     }
        // });

    $(document).on('click', function (e) {
        if (isDesktop(this)) {
            if (!$(e.target).closest('.menuItemContainer').length && $('.menuItemContainer.show').length) {
                $('.menuItemContainer.show').removeClass('show');
                $('.submenuItemContainer.show').removeClass('show');
                $('.nav-item').removeClass('navSelected');
                $('.menuItemDisplay').removeClass('navSelected');
            }
        }
    });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    $('.navbar-toggler-account').click(function (e) {
        e.preventDefault();

        var isMobile = $('.search-mobile').css('display') === 'block';

        //TODO meter condición que se haga solo con usuario loggeado
        if (isMobile){
            $('.account-menu').toggleClass('in');
            $('.modal-background').show();

            $('.account-menu').removeClass('d-none');
            $('.account-menu').attr('aria-hidden', 'false');
            $('.account-menu').siblings().attr('aria-hidden', 'true');

            $('.account-menu .nav.navbar-nav .nav-link').first().focus();
        }
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
};
