

/**
 * saves the url before go to selection stores enviroment
 * @return {void}
 */
export function saveUrlInSessionStorage (url = window.location.href) {

    sessionStorage.setItem ('mymUrlPrevious', url);
}

/**
 * retuns the previous url stored before store selection
 * @return {string} previous url if exists in sessionStorage
 */
export function recoverUrlInSessionStorage() {
    const previousUrl = sessionStorage.getItem('mymUrlPrevious');
    if (previousUrl) {
        return previousUrl;
    } else {
        return null;
    }
}

/**
 * retuns boolean when the function is called from checkout page
 * @return {booblean} isCalledFromCheckout
 */
export function verifyUrlInSessionStorage() {
    const previousUrl = sessionStorage.getItem('mymUrlPrevious');
    if (previousUrl && previousUrl.endsWith('shipping')) {
        return true;
    } else {
        return false;
    }
}

/**
 * retuns miniCart Quantity
 * @return {string}
 */
export function verifyMiniCartQuantity() {
    const minicartQuantity = parseInt($('.minicart-quantity .text').text().trim(), 10);
    return minicartQuantity;
}
