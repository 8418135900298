/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>'}${message}</div>`;

    $('.cart-error').append(errorHtml);
}

module.exports = function () {
    $('body').on('click', '.save-order', (e) => {
        e.preventDefault();
    });

    $('body').on('click', '.save-order-confirmation-btn', function (e) {
        e.preventDefault();

        const url = $(this).data('action');

        $('body > .modal-backdrop').remove();
        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            success(data) {
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                }
                $.spinner().stop();
            },
        });
    });

    $('body').on('click', '.discard-order', (e) => {
        e.preventDefault();
    });

    $('body').on('click', '.discard-order-confirmation-btn', function (e) {
        e.preventDefault();

        const url = $(this).data('action');

        $('body > .modal-backdrop').remove();
        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                }
                $.spinner().stop();
            },
        });
    });
};
