import "pluggin_variable_weight/main";
import "plugin_add_online_shipping/main";
import { saveUrlInSessionStorage, recoverUrlInSessionStorage, verifyUrlInSessionStorage, verifyMiniCartQuantity } from "./redirectUrl";
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('./components/miniCart'));
    processInclude(require('./storeLocator/selectStoreModal'));

    var footer = $('#footercontent').first();
    var mainContent = $('#maincontent').first();
    var screenHeight = $(window).height();

    if (footer.length > 0 && mainContent.length > 0) {
        var footerBottomPosition = footer.position().top + footer.outerHeight( true );
        var spaceBelowFooter = screenHeight - footerBottomPosition;
        if (spaceBelowFooter > 0) {
            mainContent.css('min-height', (mainContent.height() + spaceBelowFooter));
        }
    }

    $(document).on('click', '.store-selector-buttons', function() {
        saveUrlInSessionStorage();
        
        var redirectUrl = $(this).data('url');
        window.location.href = redirectUrl;
    })

    $(document).on('click', '.store-selector-buttons-confirm', function() {
        var returnToUrl = recoverUrlInSessionStorage();
        var isCalledFromCheckout = verifyUrlInSessionStorage();

        setTimeout(() => {
            if ($('#changeStoreModal').hasClass('show')) {
                $('#btn-continue-box').on('click', function() {
                    $.spinner().start();
                    setTimeout(() => {
                        var miniCartQuantity = verifyMiniCartQuantity();
                        if (isCalledFromCheckout && miniCartQuantity === 0) {
                            returnToUrl = $('.experience-theme-responsiveIcon a').attr('href');
                        }
                        window.location.href = returnToUrl;
                    }, 2000);
                })
            } else {
                $.spinner().start();
                window.location.href = returnToUrl;
            }
        }, 2000);
    })
});
